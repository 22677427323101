import { NgModule } from '@angular/core';
import { RootComponent } from './root/root.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { AppRoutingModule } from '../app-routing.module';
import { NavigationComponent } from './navigation/navigation.component';

import { MaterialModule } from '@funceme/material';

@NgModule({
  declarations: [
    RootComponent, 
    HeaderComponent, 
    FooterComponent, 
    NavigationComponent,
  ],
  imports: [
    AppRoutingModule,
    MaterialModule,
  ]
})
export class LayoutModule { }
