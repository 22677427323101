import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.css']
})
export class RootComponent implements OnInit, OnDestroy {

  @ViewChild('sidenav',{static: false}) sidenav: MatSidenav;

  fullscreen: boolean = false;

  constructor(
    private router: Router
  ) {
    
    const navEndEvents$ = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    );

    navEndEvents$.subscribe((event: NavigationEnd) => { });

  }

  ngOnInit() { }

  ngOnDestroy() { }

  close()
  {
    this.sidenav.close();
  }

  link(url: string)
  {
    this.close();
    this.router.navigate([url]);
  }
}
