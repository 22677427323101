import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { RootComponent } from './layout/root/root.component';
import { ErroComponent } from './pages/erro/erro.component';

const routes: Routes = [
  
  {
    path: '',
    component: RootComponent, 
    children:  [
      {
        path: '',
        loadChildren: () => import('./pages/sequeiro/sequeiro.module').then(m => m.SequeiroModule),
        pathMatch: 'full'
      },

      {
        path: '404',
        component: ErroComponent
      },

      { 
        path: '**',
        component: ErroComponent, 
        pathMatch: 'full' 
      }
    ],
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
